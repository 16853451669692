import React, { useCallback, useContext, useMemo } from "react";
import "../../components/ButtonIcons/RegulationButtonIcons.scss";
import { useTranslation } from "react-i18next";
import { Button, Icon, IconButton, Tag } from "../../../../../uiKit";
import { defineAllow } from "../../containers/regulation/helpers";
import { allowsRegulationKeys } from "../../misc/consts";
import { IRegulationViewHeader } from "./RegulationViewHeader.interface";
import { CancelButton } from "../../components/ButtonIcons/CancelButton";
import { SaveButton } from "../../components/ButtonIcons/SaveButton";
import { EditButton } from "../../components/ButtonIcons/EditButton";
import { AddToSection } from "../../components/ButtonIcons/AddToSection";
import { AccessSettingsButton } from "../../components/ButtonIcons/AccessSettingsButton";
import { DeleteButton } from "../../components/ButtonIcons/DeleteButton";
import { Breadcrumbs } from "../../../../../elements/breadcrumbs/Breadcrumbs";
import { breadcrumbsKeys } from "../../../../../elements/breadcrumbs/Breadcrumbs.interface";
import { GlobalAuthorizedContext } from "../../../../../../contexts/globalAuthorizedContext";
import { FiSidebar } from "@react-icons/all-files/fi/FiSidebar";
import { AssignStudyButton } from "../../components/ButtonIcons/AssignStudyButton";
import "./RegulationHeader.scss";
import { ActionButton } from "../../components/ButtonIcons/ActionButton";
import { BreadcrumbsNew } from "../../../../../elements/breadcrumbsNew/BreadcrumbsNew";
import { RegulationIndicator } from "../../components/RegulationIndicator/RegulationIndicator";
import { TagLabel } from "../../../../../uiKit/dataDisplay/TabLabel/TagLabel";
import { TagLabelColor } from "../../../../../uiKit/dataDisplay/TabLabel/TagLabel.interface";
import { useRootStore } from "../../../../../../hooks";
import { LucideClock } from "lucide-react";
import { getStatusLabelColor } from "../../../../../../utils/getStatusLabelColor";
import { RegulationStatus } from "../../../../../../constants/regulationStatus";
import { PrintButton } from "../../components/ButtonIcons/PrintButton";

export const RegulationViewHeader: React.FC<IRegulationViewHeader> = (props) => {
  const { t } = useTranslation();
  const globalAuthorizedContext = useContext(GlobalAuthorizedContext);

  const isHashPermissionAddedToSection = useMemo(() => {
    return defineAllow(allowsRegulationKeys.edit, props.allowedActions);
  }, [props.allowedActions]);

  const isHashPermissionDelete = useMemo(() => {
    return defineAllow(allowsRegulationKeys.delete, props.allowedActions);
  }, [props.allowedActions]);

  const handleClickPublish = useCallback(() => {
    props.onPublish();
  }, [props.regulationId]);

  return (
    <div className="regulation-view-header">
      {(props.isCreator || (props.isDraft && props.isPublisher)) && (
        <TagLabel
          text={ t(`ui:regulation_status.${props.status}`)}
          color={getStatusLabelColor(props.status!)}
          icon={props.status === RegulationStatus.OnReview ? <LucideClock size={12} /> : null}
          // color={item.labelColor ?? TagLabelColor.primary}
          // icon={item.labelIcon ?? null}
        />
        // <TagLabel
        //   text={tagLabelText}
        //   color={tagLabelColor}
        //   icon={props.isUnderApproval ? <LucideClock size={12} /> : null}
        // />
      )}
      {!globalAuthorizedContext?.regulation?.isSidebarOpen && (
        <IconButton
          // disabled={!props.metrics.length && !props.availableDashboards.length && !props.myDashboards.length}
          className="flex-shrink-0"
          onClick={() => globalAuthorizedContext?.regulation?.onSidebarOpenChange?.()}
          children={<Icon component={() => <FiSidebar />} />}
        />
      )}
      {props.breadcrumbsList.length > 1 && (
        <div className="regulation-view-header-breadcrumbs">
          <BreadcrumbsNew
            breadcrumbsList={props.breadcrumbsList}
          />
        </div>
      )}

      <div className="regulation-view-header-buttons">
        {defineAllow(allowsRegulationKeys.reject, props.allowedActions) && (
          <Button variant="filled" loading={props.isLoadingRejectBtn} onClick={props.onReject}>
            {t("ui:button.reject")}
          </Button>
        )}
        {defineAllow(allowsRegulationKeys.publish, props.allowedActions)
          && !defineAllow(allowsRegulationKeys.requestPublication, props.allowedActions) && (
          <Button variant="filled" loading={props.isLoadingApproveBtn} onClick={handleClickPublish}>
            {t("ui:button.publish")}
          </Button>
        )}
        {defineAllow(allowsRegulationKeys.requestPublication, props.allowedActions) && (
          <Button loading={props.isLoadingSubmitApproval} variant="filled" onClick={props.onOpenSelectApproverDialog}>
            {t("ui:button.request_publication")}
          </Button>
        )}

        {defineAllow(allowsRegulationKeys.edit, props.allowedActions) && (
          <>
            {props.isEditable ? (
              <>
                <Button
                  loading={props.isLoadingSaveChangesBtn}
                  onClick={props.onSaveDraftChanges}
                  variant="filled"
                  className="button-icon_16"
                >
                  {t("ui:button.save")}
                </Button>
                <Button
                  onClick={props.onCancelEditable}
                  variant="default"
                  className="button-icon_16"
                >
                  {t("ui:button.cancel")}
                </Button>
                {/*<CancelButton onClick={props.onCancelEditable} />*/}
                {/*<SaveButton*/}
                {/*  loading={props.isLoadingSaveChangesBtn}*/}
                {/*  disabled={!props.hasDraftChanges}*/}
                {/*  onClick={props.onSaveDraftChanges}*/}
                {/*/>*/}
              </>
            ) : (
              <EditButton
                onClick={props.isEditable ? () => props.onChangeEditable(false) : () => props.onChangeEditable(true)}
              />
            )}
          </>
        )}

        {/*{defineAllow(allowsRegulationKeys.edit, props.allowedActions) && props.hasActiveContent && (*/}
        {/*  <AddToSection loading={props.isLoadingAddToSectionBtn} onClick={props.onOpenFindSectionDialog} />*/}
        {/*)}*/}
        {defineAllow(allowsRegulationKeys.edit, props.allowedActions) && (
          <AccessSettingsButton
            disabled={props.withoutATopic}
            onClick={props.onOpenAccessSettingsDialog}
          />
        )}
        {defineAllow(allowsRegulationKeys.assignment, props.allowedActions) && (
          <AssignStudyButton onClick={props.onOpenAssignForStudyDialog} />
        )}
        {!props.isEditable && (
          <PrintButton onClick={props.onPrint}/>
        )}

        {(isHashPermissionAddedToSection || isHashPermissionDelete)  && (
          <ActionButton
            onOpenFindSectionDialog={props.onOpenFindSectionDialog}
            onOpenDeleteRegulationDialog={props.onOpenDeleteRegulationDialog}
            isHashPermissionAddedToSection={isHashPermissionAddedToSection}
            isHashPermissionDelete={isHashPermissionDelete}
          />
        )}
      </div>
    </div>
  );
};
